import React from 'react';
import {Helmet} from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import BottomBar from './components/BottomBar';
import TopBar from './components/TopBar/navbar';
import { LoginContextProvider } from './contexts/login_context';
import Dashboard from './pages/dashboard';
import Faq from './pages/faq';
import FirstAccess from './pages/first_access';
import Home from './pages/home';
import Profile from './pages/profile';
import RequestAccess from './pages/request-access';
import ResetPassword from './pages/reset_password';
import SearchMany from './pages/search_many_home';
import SearchOneApi from './pages/search_one_home';
import Terms from './pages/terms';
import User from './pages/user';
import TagManager from 'react-gtm-module';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getConfig } from "./config/config";

const tagManagerArgs = {
    gtmId: getConfig().GOOGLE_TAG_MANAGER
}
 
TagManager.initialize(tagManagerArgs)

export const UserSearchContext = React.createContext(null);

function App() {

    return (


        
        <LoginContextProvider>
            <GoogleReCaptchaProvider reCaptchaKey={getConfig().RECAPTCHA_SITE}>
            <Helmet>
                {/* <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" data-domain-script="9553094d-81ed-4ada-babd-2f0c3cf5c617" ></script> */}
               
               
                <script type="text/javascript">
                   
                </script>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />

                {/* Headers de segurança */}
        
                <title>Verified by GS1 I GS1 Brasil</title>
            </Helmet>

            <Router>

                <div id='s4-workspace' className='ms-core-overlay'>
                    <div id='s4-bodyContainer'>
                        <TopBar />
                        <ToastContainer
                            position="top-right"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            />
                        <div id="contentRow">
                            <div id="contentBox">
                                <div id='DeltaPlaceHolderMain'>
                                    <Routes>
                                        
                                        <Route path='/' element={<Home />} />
                                        <Route path='/faq' element={<Faq/>} />                                        
                                        <Route path='/homeApi' element={<SearchOneApi/>} />
                                        <Route path='/search-many' element={<SearchMany/>} />
                                        <Route path='/reset-password' element={<ResetPassword/>} />
                                        <Route path='/request-access' element={<RequestAccess/>} />
                                        <Route path='/first-access' element={<FirstAccess/>} />
                                        <Route path='/user' element={<User/>} />
                                        <Route path='/terms' element={<Terms/>} />
                                        <Route path='/profile' element={<Profile/>} />
                                        <Route path='/dashboard' element={<Dashboard/>} />
                                        
                                        
                                    </Routes>
                                    <BottomBar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Router>
            </GoogleReCaptchaProvider>
        </LoginContextProvider>

    );
}
  
export default App;